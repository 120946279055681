import React from 'react';
import { TextField, Autocomplete, Button } from '@mui/material';
import { AIModelsResponse } from '../../models/AIChat/AIChat.model';

interface ConfigurationProps {
    UserAIChats: string[] | null;
    chatName: string;
    setChatName: React.Dispatch<React.SetStateAction<string>>;
    AIModels: AIModelsResponse[] | null;
    AIModelName: string;
    setAIModelName: React.Dispatch<React.SetStateAction<string>>;
    handleDelete: () => Promise<void>
    maxTokens: number;
    setMaxTokens: React.Dispatch<React.SetStateAction<number>>;
    temperature: number;
    setTemperature: React.Dispatch<React.SetStateAction<number>>;
}

export function Configuration({
    UserAIChats = [],
    chatName,
    setChatName,
    AIModels = [],
    AIModelName,
    setAIModelName,
    handleDelete,
    maxTokens,
    setMaxTokens,
    temperature,
    setTemperature,
}: ConfigurationProps) {
    return (
        <div className="flex flex-col gap-4 p-4 bg-gray-100 rounded">
            <div className="flex items-center gap-2">
                <Autocomplete
                    className="flex-1"
                    freeSolo
                    options={UserAIChats || []}
                    value={chatName}
                    onInputChange={(event, newInputValue) => setChatName(newInputValue)}
                    onChange={(event, newValue) => setChatName(newValue || '')}
                    renderInput={(params) => (
                        <TextField {...params} label="Chat Name" variant="outlined" />
                    )}
                />
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                    disabled={!chatName || !(UserAIChats || []).includes(chatName)}
                >
                    Delete
                </Button>
            </div>

            <div className="flex items-center gap-2">
                <Autocomplete
                    className="flex-1"
                    freeSolo
                    options={AIModels?.map((model) => model.id) || []}
                    value={AIModelName}
                    onInputChange={(event, newInputValue) => setAIModelName(newInputValue)}
                    onChange={(event, newValue) => setAIModelName(newValue || '')}
                    renderInput={(params) => (
                        <TextField {...params} label="Model" variant="outlined" />
                    )}
                />
            </div>

            <label htmlFor="maxTokens">Max Tokens:</label>
            <input
                type="number"
                id="maxTokens"
                value={maxTokens}
                onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
            />

            <label htmlFor="temperature">Temperature:</label>
            <input
                type="number"
                id="temperature"
                value={temperature}
                onChange={(e) => setTemperature(parseFloat(e.target.value))}
                step="0.1"
                min="0"
                max="2"
            />
        </div>
    );
}