import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatHistory } from './ChatHistory';
import { ChatForm } from './ChatForm';
import { Configuration } from './Configuration';
import { useRequest } from '../../hooks/useRequest';
import { isEmpty, isNil } from 'lodash';
import { AIModelsResponse, Message } from '../../models/AIChat/AIChat.model';
const ActiveStyle = 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
const InactiveStyle = 'bg-gray-200 hover:bg-gray-300 text-black font-medium py-2 px-4 rounded'



export function AIChat() {
  const { i18n } = useTranslation();
  const [maxTokens, setMaxTokens] = useState(4096);
  const [temperature, setTemperature] = useState(1);
  const [message, setMessage] = useState('');
  const [chatName, setChatName] = useState('');
  const [AIModelName, setAIModelName] = useState('');
  const [conversation, setConversation] = useState<
    Message[]
  >([]);
  const [activeTab, setActiveTab] = useState('chat');

  const { data: UserAIChats, error: UserAIChatsErrorResponse, loading: waitingForUserAIChats, fetchData: GetUserAIChats } = useRequest<string[]>({
    url: `${process.env.REACT_APP_BACKEND_URL}/AI/chats`,
    autoFetch: true,
  });

  const { data: AIModels, error: AIModelsErrorResponse, loading: waitingForAIModels, fetchData: GetAIModels } = useRequest<AIModelsResponse[]>({
    url: `${process.env.REACT_APP_BACKEND_URL}/AI/AIModels`,
    autoFetch: true,
  });

  const { fetchData: GetUserAIChat } = useRequest<Message[]>({
    url: `${process.env.REACT_APP_BACKEND_URL}/AI/getChat`,
    method: 'POST'
  });

  const { fetchData: DeleteUserAIChat } = useRequest<void | string>({
    url: `${process.env.REACT_APP_BACKEND_URL}/AI/deleteChat`,
    method: 'POST'
  });

  useEffect(() => {
    const fetchAIModels = async () => {
      try {
        const AIModels = await GetAIModels();
        if (!isNil(AIModels) && !isEmpty(AIModels)) {
          setAIModelName(AIModels[0].id);
        }
      } catch (error) {
        console.error('Error fetching AI Models:', error);
      }
    };

    fetchAIModels();
  }, []);

  const handleDelete = async () => {
    const confirmed = window.confirm(
      i18n.t('AIChat.DELETE_CHAT_WARRNING', { chatName })
    );

    if (!confirmed) {
      return;
    }

    await DeleteUserAIChat({ body: { chatName } })
    await GetUserAIChats()
  };

  const { loading: waitingForAnswer, fetchData: GetAIResponse } = useRequest<any>({
    url: `${process.env.REACT_APP_BACKEND_URL}/AI/chat`,
    method: 'POST'
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) return;
    const newMessage = { role: 'user', content: message };
    setConversation((prev) => [...prev, newMessage]);
    const requestBody = {
      model: AIModelName,
      chatName,
      message,
      max_tokens: maxTokens,
      temperature,
    };
    setMessage('');
    try {
      const AIResponse = await GetAIResponse({ body: requestBody })
      if (!isEmpty(chatName) && AIResponse.choices[0].history) {
        // Replace the conversation with history if `withHistory` is enabled
        setConversation(AIResponse.choices[0].history);
      } else {
        // Add only the new response to the conversation
        setConversation((prev) => [...prev, AIResponse.choices[0]?.message]);
      }
    } catch (error: any) {
      setConversation((prev) => [
        ...prev,
        { role: 'assistant', content: `Error: ${error.message}` },
      ]);
    }
  };

  return (
    <div className="font-sans mx-auto">
      {/* Tab Navigation */}
      <div className="flex justify-center mb-4">
        <button
          className={activeTab === 'chat' ? ActiveStyle : InactiveStyle}
          onClick={async () => {
            const conversation = await GetUserAIChat({ body: { chatName } });
            setConversation(conversation || []);
            setActiveTab('chat');
          }}
        >
          Chat
        </button>
        <button
          className={activeTab === 'config' ? ActiveStyle : InactiveStyle}
          onClick={() => {
            GetUserAIChats()
            setActiveTab('config')
          }}
        >
          Configuration
        </button>
      </div>

      {/* Tab Content */}
      <div className="flex flex-col h-[calc(100vh-160px)] p-4 border border-gray-300 rounded bg-white shadow-md">
        {activeTab === 'chat' && (
          <div className="flex flex-col h-full">
            <ChatHistory conversation={conversation} waitingForAnswer={waitingForAnswer} />
            <ChatForm message={message} setMessage={setMessage} handleSubmit={handleSubmit} waitingForAnswer={waitingForAnswer} />
          </div>
        )}
        {activeTab === 'config' && (
          <Configuration
            UserAIChats={UserAIChats}
            chatName={chatName}
            setChatName={setChatName}
            AIModels={AIModels}
            AIModelName={AIModelName}
            setAIModelName={setAIModelName}
            handleDelete={handleDelete}
            maxTokens={maxTokens}
            setMaxTokens={setMaxTokens}
            temperature={temperature}
            setTemperature={setTemperature}
          />
        )}
      </div>
    </div>
  );
}